<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'title',
          {
            rules: [{ required: true, message: '标题不能为空' }],
            initialValue: info.title
          },
        ]" placeholder="帖子标题" />
      </a-form-item>
      <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <quill-editor v-decorator="[
          'content',
          {
            rules: [{ required: true, message: '描述不能为空' }],
            initialValue: info.content
          },
        ]" @change="quillEditorChange" />
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: infoStatus,
          },
        ]">
          是否展示
        </a-checkbox>
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'is_top',
          {
            valuePropName: 'checked',
            initialValue: isTopStatus,
          },
        ]">
          是否置顶
        </a-checkbox>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { forumInfo, forumSave, forumCreate } from '@/api/forum'
import QuillEditor from '@/components/Editor/QuillEditor'

export default {
  name: 'InformationForumEdit',
  description: '帖子编辑添加',
  components: {
    QuillEditor
  },
  data() {
    return {
      value: 1,
      fontStyle: {
        fontWeight: 1000
      },
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      customLabelCol: {
        lg: { span: 4 },
        sm: { span: 7 }
      },
      customWrapperCol: {
        lg: { span: 18 },
        sm: { span: 17 }
      },
      queryData: {},
      checkPass: true,
      type: {},
      info: {
        'type': 'all'
      },
      infoStatus: false,
      isTopStatus: false,
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
    const id = this.$route.query.id
    this.queryData.id = id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.loadInfo(id)
    }
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        forumInfo({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.info = res.data.info
        if (res.data.info.status === 1) {
          this.infoStatus = true
        } else {
          this.infoStatus = false
        }
        if (res.data.info.is_top === 1) {
          this.isTopStatus = true
        } else {
          this.isTopStatus = false
        }
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回
        // setTimeout(() => {
        //     this.$router.back(-1)
        // }, 2000)
      })
    },
    quillEditorChange(html) {
      this.content = html
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.status = values.status ? 1 : 0
          values.is_top = values.status ? 1 : 0
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.queryData.id
              forumSave(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              forumCreate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
